<template>
  <div class="discussion" style="padding: 7px">
    <v-row>
      <p class="title">{{ $t("Edit Post") }}</p>
    </v-row>
    <!--preloader  -->
    <div class="preloader" style="text-align: center" v-if="loading">
      <img
        src="../../assets/img/preloader.gif"
        id="preloader-custom"
        alt="preloader"
        class
      />
    </div>

    <!-- item -->
    <v-row class="item" v-if="groupData">
      <v-col cols="8" sm="8">
        <v-row>
          <v-col sm="2" md="2" lg="2">
            <v-responsive
              v-if="post.post_user_logo"
              style="display: inline-block"
              class="rounded-circle"
            >
              <v-img
                width="50"
                height="50"
                class="member-img rounded-circle"
                style="display: inline-block"
                :src="post.post_user_logo"
              />
            </v-responsive>

            <v-responsive
              v-else
              class="rounded-circle"
              style="display: inline-block"
            >
              <v-img
                width="50"
                height="50"
                class="member-img rounded-circle"
                style="display: inline-block"
                src="../../assets/student.png"
              />
            </v-responsive>
          </v-col>
          <v-col sm="8" md="10" lg="10">
            <div class="person-info text-left" style="margin-left: -4rem">
              <p class="main-color name">
                <strong>{{ post.post_user }}</strong>
              </p>
              <p class="time">{{ post.time }}</p>
            </div>
          </v-col>
        </v-row>
      </v-col>

      <v-col cols="12" class="addTopic">
        <v-form ref="form" v-model="valid">
          <v-text-field
            solo
            v-model="post.title"
            :placeholder="$i18n.t('New Topic')"
            :rules="[validationRules.required, validationRules.maxLength225]"
          ></v-text-field>

          <v-textarea
            v-if="post.title != ''"
            solo
            v-model="post.body"
            :placeholder="$i18n.t('Details')"
            :rules="[validationRules.required]"
            style="margin-top: 3px"
          ></v-textarea>

          <vue-tags-input
            v-if="post.title != ''"
            v-model="tag"
            :autocomplete-items="filteredItems"
            style="
              width: 100%;
              max-width: unset;
              margin-bottom: 20px;
              margin-top: 3px;
            "
            :tags="post.tags"
            @tags-changed="(newTags) => (post.tags = newTags)"
          />
          <div class="attachments">
            <v-row class="images" v-if="post.attachments.image">
              <v-col
                cols="6"
                sm="3"
                v-for="(image, index) in post.attachments.image"
                :key="index"
              >
                <div class="delete-attatch text-right">
                  <v-icon
                    class="delete-attatch-icon"
                    @click.prevent="openConfirmDialog(index, image.id, 'image')"
                    color="red"
                    >close</v-icon
                  >
                </div>
                <a :href="image.url" target="_blank" class="imgContainer">
                  <img :src="image.url" class="postImg" />
                </a>
              </v-col>
            </v-row>

            <v-row class="other">
              <div class="video" v-if="post.attachments.video">
                <v-col
                  cols="6"
                  sm="3"
                  v-for="(video, index) in post.attachments.video"
                  :key="index"
                >
                  <div class="delete-attatch text-right">
                    <v-icon
                      class="delete-attatch-icon"
                      @click.prevent="
                        openConfirmDialog(index, video.id, 'video')
                      "
                      color="red"
                      >close</v-icon
                    >
                  </div>
                  <a
                    :href="file.url"
                    target="_blank"
                    v-for="(file, index) in post.attachments.video"
                    :key="index"
                    >{{ file.name }}</a
                  >
                </v-col>
              </div>

              <!-- document -->
              <div class="document" v-if="post.attachments.document">
                <v-col
                  cols="6"
                  sm="3"
                  v-for="(file, index) in post.attachments.document"
                  :key="index"
                >
                  <div class="delete-attatch text-right">
                    <v-icon
                      class="delete-attatch-icon"
                      @click.prevent="
                        openConfirmDialog(index, file.id, 'document')
                      "
                      color="red"
                      >close</v-icon
                    >
                  </div>

                  <a :href="file.url" target="_blank">
                    <!-- <v-icon class="main-color">description</v-icon> -->
                    <span>{{ file.name }}</span>
                  </a>
                </v-col>
              </div>

              <!-- audio -->
              <div class="audio" v-if="post.attachments.audio">
                <v-col
                  cols="6"
                  sm="3"
                  v-for="(audio, index) in post.attachments.audio"
                  :key="index"
                >
                  <div class="delete-attatch text-right">
                    <v-icon
                      class="delete-attatch-icon"
                      @click.prevent="
                        openConfirmDialog(index, audio.id, 'audio')
                      "
                      color="red"
                      >close</v-icon
                    >
                  </div>

                  <v-icon class="main-color">audiotrack</v-icon>
                  <a
                    :href="file.url"
                    target="_blank"
                    v-for="(file, index) in post.attachments.audio"
                    :key="index"
                    >{{ file.name }}</a
                  >
                </v-col>
              </div>
            </v-row>
          </div>
          <div>
            <p v-if="fileNames != ''">{{ fileNames }}</p>
            <v-row>
              <v-col sm="10">
                <input
                  type="file"
                  class="myFileBrowser"
                  multiple
                  ref="attachment"
                  @change="readFiles"
                />
                <v-icon class="main-color text-right" style="cursor: pointer"
                  >attach_file</v-icon
                >
              </v-col>
              <v-col sm="1" class="text-right">
                <v-btn
                  title="cancel"
                  @click.prevent="cancel"
                  class="main-color modal-btn-cancel cancelBtn"
                  style="cursor: pointer"
                  >{{ $t("Cancel") }}</v-btn
                >
              </v-col>
              <v-col sm="1">
                <v-btn
                  class="main-color modal-btn-save saveBtn"
                  style="cursor: pointer"
                  title="Save"
                  @click.prevent="savePost"
                  >{{ $t("Edit") }}</v-btn
                >
              </v-col>
            </v-row>
          </div>
        </v-form>
      </v-col>
    </v-row>
    <v-dialog
      v-model="deletePostDialog"
      persistent
      max-width="300"
      class="dialog_confirm"
    >
      <v-card>
        <v-card-title class="headline">{{
          $t("Confirmation Message")
        }}</v-card-title>

        <v-card-text>{{
          $t("Are you sure you want to permanently remove this post?")
        }}</v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            class="modal-btn-cancel noBtn"
            @click="deletePostDialog = false"
            id="noBtn"
            ref="always_focus"
            >{{ $t("No") }}</v-btn
          >
          <v-btn
            id="yesBtn"
            @click="deletePost"
            class="modal-btn-save yesBtn"
            >{{ $t("Yes") }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="confirmDialog"
      persistent
      max-width="300"
      class="dialog_confirm"
    >
      <v-card>
        <v-card-title class="headline">{{
          $t("Confirmation Message")
        }}</v-card-title>

        <v-card-text
          >{{ $t("Are you sure you want to permanently remove this") }}
          {{ type }}?</v-card-text
        >
        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            class="modal-btn-cancel noBtn2"
            @click="confirmDialog = false"
            >{{ $t("No") }}</v-btn
          >
          <v-btn
            @click="deleteItem(deletedItem, indexItem, type)"
            class="modal-btn-save yesBtn2"
            >{{ $t("Yes") }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-snackbar
      v-model="snack"
      :timeout="snackTime"
      :color="snackColor"
      :right="true"
      :top="true"
    >
      <!-- {{ snackText }} -->

      <span class="white--text">{{ snackText }}</span>

      <v-btn text @click="snack = false">{{ $t("Close") }}</v-btn>
    </v-snackbar>
  </div>
</template>

<script>
import VueTagsInput from "@johmun/vue-tags-input";
import axios from "axios";
import { validationMixin } from "../../mixins/validationMixin";
export default {
  components: {
    VueTagsInput,
  },
  data() {
    return {
      tag: "",
      indexItem: "",
      tags: [],
      fileNames: "",
      deletedItem: "",
      snack: false,
      snackColor: "",
      snackText: "",
      snackTime: 3000,
      confirmDialog: false,
      valid: false,
      edited: false,
      loading: false,
      post: {
        new_attachments: [],
      },
      validation_errors: [],
      deletePostDialog: false,
      deletedPost: { postIndex: "" },
      groupData: [],
      attatchType: "",
      type: "",
    };
  },
  computed: {
    filteredItems() {
      let filter = this.groupData.tagsAutoComplete;

      return filter.filter((i) => {
        return i.text.toLowerCase().indexOf(this.tag.toLowerCase()) !== -1;
      });
    },
  },
  mixins: [validationMixin],
  methods: {
    readFiles() {
      var _this = this;
      console.log(this.$refs.attachment.files);
      // this.$refs.attachment.files.forEach((file) => {
      //   if (_this.fileNames.length == 0) {
      //     _this.fileNames = file.name;
      //   } else {
      //     _this.fileNames = _this.fileNames + ", " + file.name;
      //   }
      //   _this.post.new_attachments.push(file);
      // });

      Object.keys(this.$refs.attachment.files).forEach(key => {
        if (_this.fileNames.length == 0) {
          _this.fileNames = _this.$refs.attachment.files[key].name;
        } else {
          _this.fileNames = _this.fileNames + ", " + _this.$refs.attachment.files[key].name;
        }
        _this.post.new_attachments.push(_this.$refs.attachment.files[key]);
      });
   
    },
    savePost() {
      if (!this.valid) {
        this.validate();
      } else {
        const formData = new FormData();

        if (this.post.new_attachments && this.post.new_attachments.length > 0) {
          this.post.new_attachments.forEach((file) => {
            formData.append("file[]", file);
          });
        }

        formData.append("id", this.post.id);

        formData.append("title", this.post.title);
        formData.append("body", this.post.body);
        formData.append(
          "discussion_room_id",
          this.$router.currentRoute.params.roomId
        );
        if (this.post.tags && this.post.tags.length > 0) {
          this.post.tags.forEach((tag) => {
            formData.append("tags[]", tag.text);
          });
        }
        axios
          .post(
            this.getApiUrl + "/discussionboard/posts/update/" + this.post.id,
            formData,
            {
              headers: {
                Authorization: "Bearer " + localStorage.token,
              },
            }
          )
          .then((response) => {
            if (response.data.status.error == true) {
              Object.assign(
                this.validation_errors,
                response.data.status.validation_errors
              );
            } else {
              //   this.resetPostFields();
              //   this.edited = false;
              //   location.reload();
              // this.getPosts();
              this.$refs.form.resetValidation();
              this.$router.push(
                {
                  path:
                    "/discussionboard/" +
                    this.$router.currentRoute.params.roomId,
                },
                () => {}
              );
            }
          });
      }
    },
    openConfirmDialog(index, imageId, attatchType) {
      this.confirmDialog = true;
      this.deletedItem = imageId;
      this.indexItem = index;
      this.type = attatchType;
    },
    deleteItem(item, index, type) {
      let attatchId = item;
      let attatchType = type;
      axios
        .get(
          this.getApiUrl +
            "/discussionboard/post/deleteAttachment/" +
            attatchId,
          {
            headers: {
              Authorization: "Bearer " + localStorage.token,
            },
          }
        )
        .then((response) => {
          if (response.data.status.error == false) {
            console.log(attatchType);
            if (attatchType == "image") {
              this.post.attachments.image.splice(index, 1);
            }
            if (attatchType == "document") {
              this.post.attachments.document.splice(index, 1);
            }
            if (attatchType == "video") {
              this.post.attachments.video.splice(index, 1);
            }
            if (attatchType == "audio") {
              this.post.attachments.audio.splice(index, 1);
            }
          }
        });

      this.confirmDialog = false;
    },
    resetPostFields() {
      this.post.title = "";
      this.post.body = "";
      this.post.attachments = [];
      this.post.tags = "";
      this.tags = [];
      this.tag = "";
    },
    getPosts() {
      axios
        .get(
          this.getApiUrl +
            "/discussionboard/post/" +
            this.$router.currentRoute.params.postId,
          {
            headers: {
              Authorization: "Bearer " + localStorage.token,
            },
          }
        )
        .then((res) => {
          this.loading = false;
          this.post = res.data.data;
          this.post.new_attachments = [];
        })
        .catch((err) => {
          console.log(err);
        });
    },
    approve(post_id) {
      axios
        .post(this.getApiUrl + "/discussionboard/posts/approve/" + post_id, {
          headers: {
            Authorization: "Bearer " + localStorage.token,
          },
        })
        .then((response) => {
          if (response.data.status.error == true) {
            Object.assign(
              this.validation_errors,
              response.data.status.validation_errors
            );
          } else {
            this.snack = true;
            this.snackColor = "green";
            this.snackText = "Post approved successfully";
            // location.reload();
            this.getPosts();
          }
        });
    },
    deletePostDialogOpen() {
      this.deletedPost.postIndex = this.post.id;
      this.deletePostDialog = true;
    },
    deletePost() {
      axios
        .get(
          this.getApiUrl + "/discussionboard/posts/destroy/" + this.post.id,
          {
            headers: {
              Authorization: "Bearer " + localStorage.token,
            },
          }
        )
        .then((response) => {
          if (response.data.status.error == false) {
            this.deletePostDialog = false;
            this.$router.push(
              {
                path:
                  "/discussionboard/" + this.$router.currentRoute.params.roomId,
              },
              () => {}
            );
          }
        });
    },
    cancel() {
      this.$router.push(
        {
          path: "/discussionboard/" + this.$router.currentRoute.params.roomId,
        },
        () => {}
      );
    },
  },
  mounted() {
    axios
      .get(
        this.getApiUrl +
          "/discussionboard/show/" +
          this.$router.currentRoute.params.roomId,
        {
          headers: {
            Authorization: "Bearer " + localStorage.token,
          },
        }
      )
      .then((res) => {
        this.groupData = res.data.data;
        // console.log(this.groupData.tagsAutoComplete);
        this.getPosts();
      })
      .catch((err) => {
        console.log(err);
      });
  },
};
</script>
<style lang="scss" scoped>
@import "../../styles/_variables.scss";
.discussion {
  .title {
    color: #7297ff;
    font-size: px !important;
    font-weight: bold !important;
  }
  .item {
    box-shadow: $box-shadow;
    padding: 10px 20px;
    margin-bottom: 1rem;
    .person-info {
      display: inline-block;
      margin-left: 1rem;
      .name {
        font-size: 16px;
        margin-bottom: 0 !important;
      }
      .time {
        margin-bottom: 0 !important;
        font-size: 12px;
      }
    }
    .topicContent {
      margin-top: 1rem;
      padding-left: 10px;
    }
    .topicInfo {
      border-top: 1px solid #ccc;
      border-bottom: 1px solid #ccc;
    }
  }
}

.myFileBrowser {
  opacity: 0;
  position: absolute;
  left: 0;
  z-index: 999;
}
.delete-attatch .delete-attatch-icon {
  color: red;
  padding: 10px;
  // border: 2px solid red;
  width: 40px;
  height: 40px;
  // border-radius: 50%;
  cursor: pointer;
}
</style>
